import Vue from 'vue'
import Vuex from 'vuex'
// 挂载vuex
Vue.use(Vuex)

// 创建vuex对象并向外暴露
export default new Vuex.Store({
    // 全局属性变量
    state: {
        token: window.localStorage.getItem("Authorization"),
        total_num: 0,
        defaultPageSize: window.localStorage.getItem("defaultPageSize")||'50',
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        //微信群管理
        wxGroupEditShow: false,
        wxGroupId: "",
        canfreshwxGroup: false,
        //商品管理
        goodsEditShow: false,
        goodsId: "",
        canfreshgoods: false,
        //首页推荐商品
        indexRecommendEditShow: false,
        indexRecommendId: "",
        canfreshindexRecommend: false,
        //搜索
        searchEditShow: false,
        searchId: "",
        canfreshsearch: false,
        //排行榜
        rankEditShow: false,
        rankId: "",
        canfreshrank: false,
    },
    // 全局同步方法, 调用方法,this.$store.commit("xxx")
    mutations: {
        changeToken(state, token) {
            state.token = token
        },
        changedefaultPageSize(state, defaultPageSize) {
            state.defaultPageSize = defaultPageSize
        },
        //微信群管理
        changeWxGroupEditShow(state, wxGroupEditShow) {
            state.wxGroupEditShow = wxGroupEditShow
        },
        changeWxGroupId(state, wxGroupId) {
            state.wxGroupId = wxGroupId
        },
        changeCanfreshwxGroup(state, canfreshwxGroup) {
            state.canfreshwxGroup = canfreshwxGroup
        },
        //商品管理
        changeGoodsEditShow(state, goodsEditShow) {
            state.goodsEditShow = goodsEditShow
        },
        changeGoodsId(state, goodsId) {
            state.goodsId = goodsId
        },
        changeCanfreshgoods(state, canfreshgoods) {
            state.canfreshgoods = canfreshgoods
        },
        //首页推荐商品
        changeIndexRecommendEditShow(state, indexRecommendEditShow) {
            state.indexRecommendEditShow = indexRecommendEditShow
        },
        changeIndexRecommendId(state, indexRecommendId) {
            state.indexRecommendId = indexRecommendId
        },
        changeCanfreshIndexRecommend(state, canfreshindexRecommend) {
            state.canfreshindexRecommend = canfreshindexRecommend
        },
        //搜索
        changeSearchEditShow(state, searchEditShow) {
            state.searchEditShow = searchEditShow
        },
        changeSearchId(state, searchId) {
            state.searchId = searchId
        },
        changeCanfreshSearch(state, canfreshsearch) {
            state.canfreshsearch = canfreshsearch
        },
        //排行榜
        changeRankEditShow(state, rankEditShow) {
            state.rankEditShow = rankEditShow
        },
        changeRankId(state, rankId) {
            state.rankId = rankId
        },
        changeCanfreshRank(state, canfreshrank) {
            state.canfreshrank = canfreshrank
        },
    },
    // 异步方法 调用方法,this.$store.dispatch("xxx") 
    actions: {
    },
    // Vuex属性计算,在视图里面当变量使用
    getters: {
    },
    // 模块化注册
    modules: {
    }
})