import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  mode: "history",
  base: "/",//process.env.BASE_+URL
  routes: [
    {
      path: '/login',
      component: () => import('@/view/Login'),
    },
    {
      path: '/',
      component: () => import('@/view/index.vue'),
      redirect: '/login',
      children: [
        {
          path: 'goods',
          component: () => import('@/view/goods.vue'),
        },
        {
          path: 'indexRecommend',
          component: () => import('@/view/indexRecommend.vue'),
        },
        {
          path: 'search',
          component: () => import('@/view/search.vue'),
        },
        {
          path: 'rank',
          component: () => import('@/view/rank.vue'),
        },
        {
          path: 'user',
          component: () => import('@/view/user.vue'),
        },
        {
          path: 'wxGroup',
          component: () => import('@/view/wxGroup.vue'),
        },
        {
          path: 'setting',
          component: () => import('@/view/setting.vue'),
        },
      ]
    },
    {
      path: '*',
      redirect: '/login',
    },
  ]
})
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    return next()
  }
  const tokenStr = window.localStorage.getItem("Authorization")
  if (!tokenStr) {
    return next("/login")
  }
  next()
})
export default router