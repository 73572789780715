import Vue from 'vue'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue';
import router from './routers'
import store from './store'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';
import 'core-js'
import './assets/common.css'

Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.use(Antd);
import axios from 'axios'
Vue.prototype.axios = axios

//公共token
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem("Authorization");
  return config;
}, error => {
  console.log(error)
})
//状态码
var code = function (res) {
  if (res.data.resultCode == 0) {
    return true;
  } else if (res.data.resultCode == 401) {
    window.localStorage.setItem("Authorization", "");
    window.localStorage.setItem("name", "");
    window.localStorage.setItem("id", "");
    axios.defaults.headers.post["Authorization"] = "";
    message.error(res.data.resultMsg);
    store.commit("changeToken", '')
    store.commit("changeWxGroupEditShow", false)
    store.commit("changeGoodsEditShow", false)
    store.commit("changeIndexRecommendEditShow", false)
    store.commit("changeSearchEditShow", false)
    store.commit("changeRankEditShow", false)
    router.push("/login");
    return false;
  } else {
    message.error(res.data.resultMsg);
    return false;
  }
}
Vue.prototype.$code = code
//退出登录
var logOut = function () {
  window.localStorage.setItem("Authorization", "");
  window.localStorage.setItem("nickName", "");
  window.localStorage.setItem("appId", "");
  window.localStorage.setItem("id", "");
  axios.defaults.headers.post["Authorization"] = "";
  router.push("/login");
};
Vue.prototype.$logOut = logOut;


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
